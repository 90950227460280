:root{
    --PrimaryColor: hsl(199, 100%, 33%);
    --SecondaryColor: hsl(187, 100%, 43%);
    --gradientColor: linear-gradient(to right, hsl(187, 85%, 43%), hsl(199, 100%, 33%));
    --whiteColor: hsl(0, 0%, 100%);
    --blackColor: hsl(201, 33%, 16%);
    --textColor: hsl(240, 4%, 36%);
    --whiteColorDeam: hsl(0, 0%, 93%);
    --greyText: rgb(190, 190,190);
    --inputColor: rgb(239, 239, 239);
    --bodyColor: rg(240, 240, 240);
    --cardBG: rgb(225, 225, 225);
}

.header{
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--whiteColorDeam);
    width: 100%;
    padding: 1rem;
    z-index: 1000;
    box-shadow: 0 2px 4px rgba(2, -15, -29, 0.242);

    .logo{
        color: var(--blackColor);
        font-weight: 600;
        cursor: pointer;
        align-items: center;
        justify-content: center;

        .icon{
            font-size: 25px;
            color: var(--PrimaryColor);
            transform: translateY(5px);
        }
        
    }

    .navBar{
        display: flex;
        align-items: center;
        z-index: 1;

        .navLists{

            .navItem{

                &:hover{
                    color: var(--PrimaryColor);
                }
            }
        }

        .closeNavbar{
            position: fixed;
            top: -500rem;
        }
    }

    .toggleNavbar{
        .icon{
            font-size: 25px;
            color: var(--PrimaryColor);

            &:hover.icon{
                color: var(--SecondaryColor);
            }
        }
    }

    @media screen and (max-width: 768px){
        .navBar{
            position: absolute;
            background: var(--whiteColorDeam);
            height: max-content;
            width: 80%;
            border-radius: 1rem;
            top: -500rem;
            left: 50%;
            padding: 1rem;
            transform: translate(-50%);
            box-shadow: 0 2px 4px rgba(2, 15, 29, 0.904);
            z-index: 2000;
            transition: .5s ease-in-out;

            .navLists{
                flex-direction: column;
                justify-content: center;
                width: 100%;
                margin: auto;
                padding: .5rem 0;

                .navItem{
                    padding: .5rem 0;
                    font-size: 0.9rem;
                    font-weight: 600;

                    &:hover{
                        color: var(--PrimaryColor);
                    }
                }
                .btn{
                    margin-top: 1rem;
    
                    a{
                        font-weight: 600;
                        color: var(--whiteColor);
                    }
                }
            }
            .closeNavbar{
                position: absolute;
                top: 1rem;
                right: 1.5rem;
                color: var(--PrimaryColor);

                &:hover{
                    color: var(--SecondaryColor);
                }
            }
            
        }

        .activeNavbar{
            top: 7rem;
        }
    }
}

//MEDIA QUERIES ==================================================================>

@media screen and (min-width: 769px){
    .toggleNavbar, .closeNavbar{
        display: none;
    }
    .header{
        padding: 1.5rem 2rem;

        .navBar{
            .navLists{
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: row;

                .navItem{
                    .navLink{
                        color: var(--textColor);
                        padding: 0 0.45rem;
                        font-size: 0.9rem;

                        &:hover{
                            color: var(--PrimaryColor);
                        }
                    }
                }
                .btn{
                    margin-left: 1rem;

                    a{
                        color: var(--whiteColor);
                        font-weight: 500;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 960px){
    .header{
        .navBar{
            .navLists{
                .navItem{
                    padding: 0 0.7rem;
                }
            }
        }
    }
}

@media screen and (min-width: 1240px){
    .header{
        padding: 1rem 8.5rem;
    }
}