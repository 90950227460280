:root{
    --PrimaryColor: hsl(199, 100%, 33%);
    --SecondaryColor: hsl(187, 100%, 43%);
    --gradientColor: linear-gradient(to right, hsl(187, 85%, 43%), hsl(199, 100%, 33%));
    --whiteColor: hsl(0, 0%, 100%);
    --blackColor: hsl(201, 33%, 16%);
    --textColor: hsl(240, 4%, 36%);
    --whiteColorDeam: hsl(0, 0%, 93%);
    --greyText: rgb(190, 190,190);
    --inputColor: rgb(239, 239, 239);
    --bodyColor: rgb(240, 240, 240);
    --cardBG: rgb(225, 225, 225);
}

.footer{
    width: 100%;
    position: relative;
    padding: 2rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;

    .videoDiv{
        position: absolute;
        width: 100%;
        height: 100%;

        video{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        &::after{
            content: '';
            position: absolute;
            background: rgba(10, 84, 107, 0.307);
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            mix-blend-mode: multiply;
        }
    }
    .secContent{
        width: 100%;
        height: max-content;
        padding: 2rem initial;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin: auto;
        row-gap: 2rem;
        z-index: 100;

        .contactDiv{
            justify-content: space-between;
            flex-direction: column;
            gap: 1rem;

            small{
                font-size: 13px;
                font-weight: 400;
                color: var(--whiteColor);
            }

            h2{
                color: var(--whiteColor);
                font-size: 1.8rem;
            }
        }

        .inputDiv{
            width: 100%;
            gap: 1rem;
            flex-direction: column;

            input{
                width: 100%;
                padding: .5rem .6rem;
                border-radius: 3rem;
                border: 1px solid var(--whiteColor);
                outline: none;
                background: transparent;
                color: var(--whiteColor);

                &::placeholder{
                    color: var(--whiteColor);
                    opacity: .5;
                }
            }
            .btn{
                width: 100%;
                justify-content: center;
                color: var(--whiteColor);
                font-weight: 500;
                gap: 0.5rem;

                .icon{
                    font-size: 18px;
                }
            }
        }
        .footerCard{
            position: relative;
            padding: 1rem 1rem 4rem;
            gap: 2rem;
            border-radius: 1rem;
            background: rgb(240, 240, 240);
            width: 100%;
            justify-content: center;
            flex-direction: column;
            align-items: flex-start;
            overflow: hidden;

            .footerIntro{
                flex-basis: 50%;
                justify-content: center;
                align-items: flex-start;
                flex-direction: column;
                row-gap: .5rem;

                .logoDiv{
                
                    .logo{
                        color: var(--blackColor);
                        font-weight: 600;
                        .icon{
                        font-size: 25px;
                        color: var(--PrimaryColor);
                        margin-right: 10px;
                        }
                    }
                }
    
                .footerPara{
                    font-size: 13px;
                    text-align: justify;
                    color: var(--textColor);
                }
    
                .footerSocials{
                    row-gap: .5rem;
    
                    .icon{
                        color: var(--blackColor);
                        font-size: 20px;
    
                        &:hover{
                            color: var(--PrimaryColor);
                        }
                    }
                }
            }

            .footerLinks{
                flex-basis: 50%;
                width: 100%;
                gap: 1rem;
                grid-template-columns: repeat(2, 1fr);
                justify-content: space-between;

                .linkGroup{
                    .groupTitle{
                        display: block;
                        font-weight: 600;
                        color: var(--blackColor);
                        margin-bottom: 0.5rem;
                    }
                    .footerList{
                        font-size: 15px;
                        color: var(--textColor);
                        transition: .3s ease-in-out;

                        .icon{
                            font-size: 15px;
                            color: var(--greyText);
                        }

                        &:hover{
                            color: var(--PrimaryColor);
                            transform: translate(7px);

                            .icon{
                                color: var(--SecondaryColor);
                            }
                        }
                    }
                }
            }

            .footerDiv{
                flex-direction: column;
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                background: var(--SecondaryColor);
                padding: .5rem 2rem;

                small{
                    font-size: 10px;
                    color: var(--whiteColor);
                }
            }

        }
    }
}

//MEDIA QUERIES ================================================================>

@media screen and (min-width: 500px){
    .footer{
        .secContent{
            .footerCard{
                .footerLinks{
                    grid-template-columns: repeat(3, 1fr);
                    padding-bottom: 1rem;
                }

                .footerDiv{
                    justify-content: space-between;
                    flex-direction: row;

                    small{
                        font-size: 14px;
                        color: var(--whiteColor);
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 660px){
    .footer{
        .secContent{
            .contactDiv{
                flex-direction: row;
                justify-content: space-between;

                .inputDiv{
                    width: max-content;
                    flex-direction: row;
                    justify-content: flex-start;

                    input{
                        width: 60%;
                    }

                    .btn{
                        width: max-content;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 840px){
    .footer{
        padding: 5rem 0;
        .secContent{
            .footerCard{
                flex-direction: row;

                .footerLinks{
                    grid-template-columns: repeat(3, 1fr);
                    padding: 1rem;
                }
            }
        }
    }
}

@media screen and (min-width: 1024px){
    .footer{
        .secContent{
           .footerCard{
            padding: 3rem 1rem;

            .footerIntro{
                .footerPara{
                    font-size: 15px;
                    color: var(--textColor);
                }

                .footerSocials{
                    column-gap: .5rem;
                    .icon{
                        color: var(--textColor);
                        font-size: 25px;

                        &:hover{
                            color: var(--PrimaryColor);
                        }
                    }
                }
            }

            .footerLinks{
                .linkGroup{
                    .footerList{
                        font-size: 16px;
                        padding: 5px 0;
                    }
                }
            }
           }
        }
    }
}